import React from 'react';
import type { CourseTableItem } from '../CoursesTable';
import { Button, Link } from 'components';
import { useIntl } from 'react-intl';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { NextLink } from 'common';

const useStyles = makeStyles((theme) => ({
	buttonContainerDesktop: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		gap: theme.spacing(2)
	},
	styledButtonDesktop: {
		[theme.breakpoints.down('md')]: {
			height: theme.spacing(3)
		}
	},
	darkerHoverButtonDesktop: {
		'&:hover': {
			backgroundColor: theme.palette.secondary[700]
		}
	},
	buttonContainer: {
		display: 'flex',
		gap: theme.spacing(3),

		[theme.breakpoints.down('md')]: {
			gap: theme.spacing(1)
		},
		...theme.border({ width: 1, kind: 'top', color: '#DCDCDC', radius: 0 }),
		'& a:nth-child(1)': {
			...theme.border({ width: 1, kind: 'right', color: '#DCDCDC', radius: 0 })
		}
	},
	styledButton: {
		width: '50%',
		padding: theme.spacing(1)
	}
}));

type CourseActionButtonsProps = Pick<CourseTableItem, 'courseURL' | 'bookingURL'> & {
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	dividedButtons: boolean;
};

export const CourseActionButtons = (props: CourseActionButtonsProps) => {
	const { courseURL, bookingURL, onClick, dividedButtons } = props;
	const classes = useStyles();
	const { formatMessage } = useIntl();

	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		onClick && onClick(e);
	};

	return (
		<div className={dividedButtons ? classes.buttonContainerDesktop : classes.buttonContainer}>
			<Link href={courseURL}>
				<Button
					href={courseURL}
					className={dividedButtons ? classes.styledButtonDesktop : classes.styledButton}
					variant="text"
					color="secondary"
					onClick={handleClick}
					unstyled
				>
					{formatMessage({ id: 'catalog.home.agenda.details' })}
				</Button>
			</Link>
			<NextLink href={bookingURL}>
				<Button
					className={
						dividedButtons
							? classNames(
									classes.styledButtonDesktop,
									classes.darkerHoverButtonDesktop
							  )
							: classes.styledButton
					}
					onClick={handleClick}
					color="secondary"
					variant={dividedButtons ? 'contained' : 'text'}
					unstyled
				>
					{formatMessage({ id: 'catalog.agenda.row.button.book' })}
				</Button>
			</NextLink>
		</div>
	);
};
